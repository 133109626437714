import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useForm = (callback, validate) => {
    const history = useHistory();
    const [values, setValues] = useState({
        username: '',
        email: '',
        password: '',
        password2: ''
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleSubmit = e => {
        e.preventDefault();

        setErrors(validate(values));
        setIsSubmitting(true);
    };

    async function login(email, password) {
        let item = {email, password};
        let jsonItem = JSON.stringify(item);
        let result = await fetch("http://oxygen.alenet.com/clx56dev/apirest.php",
        {
            mode: 'no-cors',
            method: "POST",
            headers: {
                "Content-Type":"application/json",
                "Accept":"application/json"
            },
            body: {
                "classname":"clxLogin",
                jsonItem
            }
        });
        history.push("/account");
        return result;
    }

    useEffect(
        () => {
            if (Object.keys(errors).length === 0 && isSubmitting) {
                callback();
            }
            if (Cookies.get("PHPSESSID")) {
                history.push("/account")
            }
        },
        // eslint-disable-next-line 
        [errors]
    );

    return { handleChange, handleSubmit, values, errors, login };
};

export default useForm;