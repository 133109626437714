
import React, { useState } from 'react';
import FormLogin from './FormLogin';
import { Redirect } from 'react-router-dom';
import './Form2.css'


const Form2 = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  function submitForm() {
    setIsSubmitted(true);
  }
  return (
    <>
      <div className='form-container-2'>
        {!isSubmitted ? (
          <FormLogin submitForm={submitForm} />
        ) : (
          <Redirect to='/account'/>
        )}
      </div>
    </>
  );
};

export default Form2;