import React from 'react';
import { useTranslation } from "react-i18next";
import {Button} from './Button';
import './HeroSection.css';
import '../App.css';

function HeroSection() {
    const {t} = useTranslation();

    return (
        <div className='hero-container'>
            <video src="/videos/city.mp4" autoPlay loop muted controls={false} />
            <h1> {t('achieve_freedom_msg')} </h1>
            <p> {t('waiting_for_msg')} </p>
            <div className='hero-btns'>
                <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>
                {t('create_account_msg')}
                </Button>
                <Button className='btns' buttonStyle='btn--primary' buttonSize='btn--large'>
                {t('get_started_msg')} <i className='far fa-play-circle' />
                </Button>
            </div>
        </div>
    )
}

export default HeroSection
