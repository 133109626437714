import React from 'react';
import '../../App.css';
import Form2 from "../Form2"

function Login()  {
    return (
        <div className="login-background">
            <img src='../../images/city1.jpg' className='background' alt='city' />
            <Form2 />
        </div>
    )
}

export default Login
