import React from "react"
import '../../App.css';
import Form from "../Form";
import './SignUp.css'

function SignUp () {
    return (
        <div className='sign-up-bakcground'>
            <img src='../../images/city1.jpg' className='background' alt='city'/>
            <Form />
        </div>
    )
}

export default SignUp;