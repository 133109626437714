import React from 'react';
import validate from './validateInfoLogin'
import useForm from './useForm';
import { useTranslation } from "react-i18next";

const FormLogin = ({ submitForm }) => {
  const { t } = useTranslation();
  const { handleChange, handleSubmit, values, errors, login } = useForm(
    submitForm,
    validate
  );

  return (
    <div className='form-content-2'>
      <form onSubmit={() =>login(values.username, values.password)} className='form-2' noValidate>
        <h1>
          {t('login_prompt')}
        </h1>
        <div className='form-inputs-2'>
          <label className='form-label-2'>{t('username')}</label>
          <input
            className='form-input-2'
            type='text'
            name='username'
            placeholder={t('username_placeholder')}
            value={values.username}
            onChange={handleChange}
          />
          {errors.username && <p>{errors.username}</p>}
        </div>
        <div className='form-inputs-2'>
          <label className='form-label-2'>{t('password')}</label>
          <input
            className='form-input-2'
            type='password'
            name='password'
            placeholder={t('password_placeholder')}
            value={values.password}
            onChange={handleChange}
          />
          {errors.password && <p>{errors.password}</p>}
        </div>
        <button 
        onClick={handleSubmit} 
        className='form-input-btn-2' type='submit'>
          {t('login')}
        </button>
      </form>
    </div>
  );
};

export default FormLogin;
