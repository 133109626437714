import Cookies from 'js-cookie';
import React from 'react'
import { useTranslation } from 'react-i18next';
import getAccount from '../getAccInfo';
import './Account.css'

function Account() {
    const {t} = useTranslation();
    let session_token = Cookies.get("PHPSESSID");
    const accountInfo = getAccount(session_token);
    const accountContent = JSON.parse(accountInfo);
    return (
        <div className='account'>
            <img src='../../images/city4.jpg' className='background' alt='city' />
            <div className='account-content-container'>
                <h1>{t('acc_cont_head')}</h1>
                <hr></hr>
                <ul>
                    <li>{t('acc_holder')} {accountContent.FIRSTNAME} {accountContent.LASTNAME}</li>
                    <li>{t('acc_email')} {accountContent.EMAIL}</li>
                    <li>{t('acc_phone')} {accountContent.PHONE}</li>
                </ul>
            </div>
        </div>
    )
}

export default Account
