import React from 'react'
import '../../App.css'
import { useTranslation } from "react-i18next";
import './Services.css'

function Services() {
    const {t} = useTranslation();
    return (
        <div className="services-background">
            <img src='../../images/city3.jpg' className='background' alt='city' style= {{ opacity: 0.7 }} />
             <h1 className='services'>{t('services')}</h1>;
        </div>
    )
}

export default Services

