export default function getAccountInfo(session_token) {
    let result = fetch("http://oxygen.alenet.com/clx56dev/apirest.php",
    {
        mode: "no-cors",
        method: "POST",
        headers: {
            "Cache-Control":"localStorage",
            "Content-Type":"application/json",
            "Accept":"text/html"
        },
        body: {
            "classname":"clxGetSessionData",
            "CLX_LOGIN_ID": JSON.stringify(session_token)
        }
    });
    return JSON.stringify(result);
}